import { type FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './top.module.scss';
import { swapCoinModel } from '@models/swap-coin.model';
import { useNavigate } from 'react-router-dom';

export const Top: FC = () => {
  const navigator = useNavigate();

  const banners = [
    // {
    //   className: styles.fud,
    //   clickEvent: () => {
    //     // swapCoinModel.updateB(
    //     //   '0xe9a376ada59b2ad927eaedc086dd0d7649b56433598ceda4f9d86ab95309e651::puff::PUFF',
    //     // );
    //     // swapCoinModel.updateA('0x2::sui::SUI');
    //     navigator(
    //       '/pools/0xbd85f61a1b755b6034c62f16938d6da7c85941705d9d10aa1843b809b0e35582/add-liquidity',
    //     );
    //   },
    // },
    {
      className: styles.turbos_sui,
      clickEvent: () => {
        // swapCoinModel.updateB(
        //   '0xe9a376ada59b2ad927eaedc086dd0d7649b56433598ceda4f9d86ab95309e651::puff::PUFF',
        // );
        // swapCoinModel.updateA('0x2::sui::SUI');
        navigator(
          '/pools/0x2c6fc12bf0d093b5391e7c0fed7e044d52bc14eb29f6352a3fb358e33e80729e/add-liquidity',
        );
      },
    },
    {
      className: styles.turbo,
      clickEvent: () => {
        swapCoinModel.updateB(
          '0xe0fbaffa16409259e431b3e1ff97bf6129641945b42e5e735c99aeda73a595ac::suiyan::SUIYAN',
        );
        swapCoinModel.updateA('0x2::sui::SUI');
        navigator(
          '/trade?input=0x2::sui::SUI&output=0xe0fbaffa16409259e431b3e1ff97bf6129641945b42e5e735c99aeda73a595ac::suiyan::SUIYAN',
        );
      },
    },
    {
      className: styles.ronda,
      clickEvent: () => {
        swapCoinModel.updateB(
          '0xdc9b462697876ff4c680e59b3756ac39a6899cfbc355a6880c60e01c8930b3cc::ronda::RONDA',
        );
        swapCoinModel.updateA('0x2::sui::SUI');
        navigator(
          '/trade?input=0x2::sui::SUI&output=0xdc9b462697876ff4c680e59b3756ac39a6899cfbc355a6880c60e01c8930b3cc::ronda::RONDA',
        );
      },
    },
  ];

  const finalBalances = banners.sort(() => Math.random() - 0.5);
  return (
    <div className={styles.top}>
      <Swiper
        modules={[Autoplay, Pagination]}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
      >
        <SwiperSlide>
          <div
            className={`${styles.slide} ${styles.ns}`}
            onClick={() => {
              navigator(
                '/pools/0xa0b55c090a286dfc77125231735077bc12a7bf865feb07accdac6f5be03f69b4/add-liquidity',
              );
            }}
          ></div>
        </SwiperSlide>
        {finalBalances.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={`${styles.slide} ${item.className}`} onClick={item.clickEvent}></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
